<template>
  <div id="message-search-results">
    <template v-if="state === 'loading' || (state === 'retrieved' && totalMessages > 0)">
      <div class="u-text-centre">
        <p>
          {{ totalMessages }}
          {{ totalMessages === 1 ? 'message' : 'messages' }}
          {{ pgettext('for number of messages found', 'found') }}
        </p>

        <div class="forum-search__controls-row">
          <div class="forum-search__controls u-fontsize-sm">
            <label
              for="messages-per-page"
              style="flex:0 1 auto;width:auto;"
            >
              {{ gettext('Messages per page:') }}
            </label>
            <select
              id="messages-per-page"
              v-model="messagesPerPage"
              class="forum-search__sm-input"
            >
              <option value="5">
                5
              </option>
              <option value="10">
                10
              </option>
              <option value="25">
                25
              </option>
              <option value="50">
                50
              </option>
              <option value="100">
                100
              </option>
            </select>
          </div>

          <div class="forum-search__controls">
            <span class="u-fontsize-sm">
              Sort by:
            </span>

            <div class="btn-group">
              <button
                v-for="(key, index) in Object.keys(orderOptions)"
                :key="index"
                class="btn btn--sm"
                :class="[ key === activeOrder ? 'btn--dark' : 'btn--light' ]"
                :data-sort-preference="key"
                @click.prevent="updateSortPreferenceAndNavigate"
              >
                {{ gettext(orderOptions[key]) }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <message-search-page-nav
        :state="state"
        :initCurrentPage="currentPage"
        :totalPages="totalPages"
        @page-nav="handlePageNav"
        @select-page="updateCurrentPage"
      />
    </template>

    <div
      v-if="state === 'loading'"
      class="alert alert--info u-text-centre"
    >
      {{ gettext('Loading...') }}
    </div>

    <div
      v-if="state === 'error'"
      class="alert alert--error u-text-centre"
    >
      {{ gettext('Error') }}
    </div>

    <template v-if="state === 'retrieved'">
      <div
        v-if="isOverLimit"
        class="alert alert--info"
      >
        <p>You cannot query more than 10,000 messages at a time. Please try:</p>
        <ul>
          <li>
            Adding some of the filters
          </li>
          <li>
            Changing your search terms
          </li>
          <li>
            <button
              class="link"
              type="button"
              @click.prevent="resetForm"
            >
              Clearing all criteria and starting over
            </button>
          </li>
        </ul>
      </div>
      <div
        v-else-if="!messages.data.messages || totalMessages === 0"
        class="alert alert--info"
      >
        <p>No messages found! Please try:</p>
        <ul>
          <li>
            Removing some of the filters
          </li>
          <li>
            Changing your search terms
          </li>
          <li>
            <button
              type="button"
              class="link"
              @click.prevent="resetForm"
            >
              Clearing all criteria and starting over
            </button>
          </li>
        </ul>
      </div>

      <template v-if="all_messages && totalMessages > 0">
        <notes-legend />
        <div
          v-for="(message, index) in all_messages"
          :key="index"
        >
          <message-result-forum
            v-if="message._source.forum || message._source.forum_id"
            :mediaSearchUrl="mediaSearchUrl"
            :message="message"
            @bookmarkRemoved="$emit('bookmarkRemoved')"
            @refreshed="refreshed"
          />

          <message-result-chat
            v-else
            :mediaSearchUurl="mediaSearchUrl"
            :chatTimelineUrl="chatTimelineUrl"
            :message="message"
            @bookmarkRemoved="$emit('bookmarkRemoved')"
            @refreshed="refreshed"
          />
        </div>
      </template>
    </template>

    <template v-if="state === 'loading' || (state === 'retrieved' && totalMessages > 0)">
      <message-search-page-nav
        :state="state"
        :initCurrentPage="currentPage"
        :totalPages="totalPages"
        @page-nav="handlePageNav"
        @select-page="updateCurrentPage"
      />
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import NotesLegend from '../notes/NotesLegend'
import MessageSearchPageNav from './MessageSearchPageNav'
import MessageResultForum from './MessageResultForum'
import MessageResultChat from './MessageResultChat'

export default {
  name: 'MessageSearchResults',

  components: {
    NotesLegend,
    MessageSearchPageNav,
    MessageResultForum,
    MessageResultChat,
  },

  props: {
    mediaSearchUrl: {
      type: String,
      default: ''
    },
    chatTimelineUrl: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    },
    messages: {
      type: Object,
      required: true
    },
    totalMessages: {
      type: Number,
      default: 0
    },
    isOverLimit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      perPageMax: 100,
      perPageMin: 1,
      all_messages: '',
      orderOptions: {
        'asc': 'Oldest',
        'desc': 'Newest',
        'relevance': 'Most Relevant'
      },
      url: window.location.href
    }
  },

  computed: {
    limit() {
      return this.$store.state.limit
    },
    start() {
      return this.$store.state.start
    },

    currentPage() {
      return Math.floor(this.start / this.limit) + 1
    },
    totalPages() {
      return Math.ceil(this.totalMessages / this.limit)
    },
    messagesPerPage: {
      get() {
        return this.$store.state.limit
      },
      set(count) {
        let validatedCount
        let typedCount = parseInt(count)
        let max = parseInt(this.perPageMax)
        let min = parseInt(this.perPageMin)

        if (typedCount > max) {
          validatedCount = max
        } else if (typedCount < min) {
          validatedCount = min
        } else {
          validatedCount = typedCount
        }

        this.$store.commit('limit', parseInt(validatedCount))
        return parseInt(validatedCount)
      },
    },
    totalNumberOfOrderOptions() {
      return Object.keys(this.orderOptions).length
    },
    activeOrder() {
      return this.$store.state.sortPreference
    }
  },

  watch: {
    state(oldState, newState) {
      this.all_messages = this.messages.data.messages
    }
  },

  methods: {
    prevPage() {
      this.scrollToTopControls()
      this.$store.commit('start', this.start - this.limit)
    },

    nextPage() {
      this.scrollToTopControls()
      this.$store.commit('start', this.start + this.limit)
    },

    refreshed() {
      const csrftoken = Cookies.get('csrftoken')
      let currentUrl = new URL(window.location)
      axios.get('/en/message-search/get_posts', {params: currentUrl.searchParams}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.all_messages = response.data.messages
      })
    },

    handlePageNav(data) {
      if (data === 'prev') this.prevPage()
      if (data === 'next') this.nextPage()
    },

    updateCurrentPage(page) {
      this.$store.commit('start', (this.limit * parseInt(page)) - 1)
    },

    updateSortPreferenceAndNavigate(event) {
      // Check if this sort preference is already active before doing anything:
      if (event.target.dataset.sortPreference === this.activeOrder) return false

      // Update the sort preference in the store.
      this.$store.commit('setSortPreference', event.target.dataset.sortPreference)

      // ForumSearch.vue handles the rest once it detects sortPreference has been updated
    },

    scrollToTopControls() {
      let elBounds = document.getElementById('message-search-results').getBoundingClientRect()

      if (elBounds.top < 0) {
        window.scrollTo({
          top: window.pageYOffset + elBounds.top,
          left: 0,
          behavior: 'smooth'
        })
      }
    },

    resetForm() {
      this.$emit('removeDocId')
      this.$store.dispatch('resetState')
    },
  },

}

</script>

<style>
.note-icon {
  width: 30px;
  height: auto;
}
</style>
