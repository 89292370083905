<template>
  <div>
    <note-buttons
        :noteId="mediaData.sha1"
        :noteName="null"
        :noteLinked="messageSource._id"
        :noteItem="'media'"
        :hasNotes="mediaData.has_media_notes"
        :indexType="indexType"
        :userNoteSiteName="messageSource._source.site_name"
        :siteLink="absMediaLink(mediaData.sha1)">
    </note-buttons>
    <dl class="dl-table dl-table--dense u-mb-xs">
      <div class="dl-table__row">
        <dt>Media SHA1:</dt>
        <dd>
          <a :href="mediaSearchLink(mediaData.sha1)">
            <code>{{ mediaData.sha1 }}</code>
          </a>
          <img v-if="mediaData.in_archive" class="archive-linked-icon" src="/static/images/icons/archive_linked.svg" :title="getArchiveLinkTitle()">
        </dd>
      </div>
      <div v-if="mediaData.classification" class="dl-table__row">
        <dt>Classification:</dt>
        <dd>{{ mediaData.classification }}</dd>
      </div>
      <div v-if="mediaData.series" class="dl-table__row">
        <dt>Series:</dt>
        <dd v-if="mediaData.series.length > 0">{{ mediaData.series.join(", ") }}</dd>
        <dd v-else>No series</dd>
      </div>
      <div v-if="mediaData.in_watchlist !== null && mediaData.label !== null">
        <add-media-label :hash="mediaData.sha1" :is_sha1="true" :in_watchlist="mediaData.in_watchlist" :sha1_label="mediaData.label" :pdna_label="''" @add-data-watchlist="add_data_watchlist"/>
      </div><br>
    </dl>
  </div>
</template>

<script>
import NoteButtons from "../notes/NoteButtons";
import AddMediaLabel from "../watchlist/AddMediaLabel";
import MessageSearchArchiveMedia from "./MessageSearchArchiveMedia";
import Cookies from 'js-cookie'
import axios from 'axios'

export default {
  name: 'MessageResultMediaDetails',
  components: {
    NoteButtons,
    AddMediaLabel,
    MessageSearchArchiveMedia
  },
  props: {
    mediaData: {
      type: Object,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    messageSource: {
      type: Object,
    },
    indexType: {
      type: String,
      required: true
    },
    parentType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
    }
  },

  methods: {
    absMediaLink(imgHash) {
      return new URL(this.mediaSearchUrl + '?sha1=' + imgHash, document.baseURI).href
    },
    mediaSearchLink(imgHash) {
      return `${this.mediaSearchUrl}?sha1=${imgHash}`
    },
    add_data_watchlist(args) {
      // add a media entry to the watchlist
      var labelInput = args['label']
      var data_val = args['data_val']
      var media_data = {}
      var is_pdna

      if (data_val != null) {
        if (data_val.trim().length === 288) {
          is_pdna = 'True'
          media_data = {
            label: labelInput,
            pdna_key: data_val
          }
        } else if (data_val.trim().length === 32) {
          is_pdna = 'False'
          media_data = {
            label: labelInput,
            sha1_key: data_val
          }
        }

        if (Object.keys(media_data).length != 0) {
          const csrftoken = Cookies.get('csrftoken')
          axios.post('/en/watchlist/add/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {console.log("got a response! %o", resp)}
          ).catch(
            err => {console.log("something went wrong! %o", err)}
          )
        }
        data_val = ''
      }
    },
    getArchiveLinkTitle() {
      if (this.parentType === "archive") {
        return "Media is also found separately on message"
      } else {
        return "Media is also found in archive on message"
      }
    }
  }
}
</script>

<style>
.archive-details[open] > summary {
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid hsl(11, 3%, 90%);
}
.archive-details ul {
  margin: 0;
}
.details_hidden {
  overflow: hidden;
  max-height: 0;
}
.archive-linked-icon {
  width: 22px;
  height: auto;
}
</style>
