<template>
  <p class="forum-search__page-buttons u-fontsize-sm">
    <button
      v-if="currentPage > 1"
      @click.prevent="$emit('page-nav', 'prev')"
      :aria-label="gettext('Previous page')"
      :disabled="state === 'loading'"
      type="button"
      class="btn btn--sm btn--dark forum-search__page-button">
      <svg
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 100 100">
        <path d="M53 98l12-12-27-27 40 1h16V40H78l-40 1 27-27L53 2 5 50z"/>
      </svg>
    </button>
    <span class="u-px-md">
      {{ pgettext('page in pagination', 'Page') }}
      <input
        type="number"
        id="current-page"
        :min="1"
        :max="totalPages"
        v-model="currentPage"
        @focus="$event.target.select()"
        class="forum-search__xs-input u-mx-xs">
      {{ pgettext('## of ##', 'of') }}
      {{ totalPages }}
    </span>
    <button
      v-if="currentPage < totalPages"
      @click.prevent="$emit('page-nav', 'next')"
      :aria-label="gettext('Next page')"
      :disabled="state === 'loading'"
      type="button"
      class="btn btn--sm btn--dark forum-search__page-button">
      <svg
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 100 100">
        <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z"/>
      </svg>
    </button>
  </p>
</template>

<script>
export default {
  name: 'MessageSearchPageNav',

  props: {
    state: {
      type: String
    },
    initCurrentPage: {
      type: Number
    },
    totalPages: {
      type: Number
    },
  },

  computed: {
    currentPage: {
      get() {
        return this.initCurrentPage
      },
      set(pageNum) {
        let validatedPageNum

        if (pageNum > this.totalPages) {
          validatedPageNum = this.totalPages
        } else if (pageNum < 1) {
          validatedPageNum = 1
        } else {
          validatedPageNum = pageNum
        }

        this.$emit('select-page', validatedPageNum)
        return validatedPageNum
      },
    },
  },
}
</script>
