<template>
  <div data-app>
    <v-row>
      <v-col
        cols="8"
        sm="8"
      >
        <v-card
          class="mx-auto"
          tile
        >
          <v-list>
            <div class="user-header">
              <span>Username: </span>
              <h2>{{ userName }}</h2>
            </div>
            <div v-if="totalMessages > 0">
            <div class="user-site">
              Site: <strong>{{ siteName }}</strong>
            </div>

            <v-list-item>
              Total Posts:&nbsp;<strong>{{ totalMessages }}</strong>
              <a
                class="u-pl-sm u-fontsize-sm"
                :href="'/en/message-search/?filters=username:'+ userName +',site_name:' + siteName"
                target="_blank"
              >
                View Posts
              </a>
            </v-list-item>
            <v-list-item>
              <v-list-item-group
                v-model="selectedItem"
                color="primary"
              >
                <div>
                  Last Post On: <strong class="u-fontsize-sm">{{ lastPostedDate }}</strong>
                </div>
              </v-list-item-group>
            </v-list-item>
         <v-list-item style="background-color: #e4e4e4" v-if="totalMessages>100">
          Showing data for most recent 100 posts
         </v-list-item>
            <v-list-item>
              <v-list-item-group>
                Post With Media: <strong>{{ totalMediaPost.length }}</strong>
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="accent"
                    size="20"
                >
                </v-progress-circular>
                <button
                  v-if="totalMediaPost.length > 0"
                  class="show-posts"
                  @click="postWithMediaModal = true"
                >
                  Show Posts
                </button>
              </v-list-item-group>
            </v-list-item>
            <v-list-item>
              <div class="text-center">
                <v-dialog
                  v-model="mediaModal"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="#c22c3f"
                      dark
                      class="modal-button"
                      :disabled="mediaData.length === 0"
                      v-bind="attrs"
                      v-on="on"
                      @click="get_media_details"
                    >
                      Show Media
                      <strong v-if="loading===false">({{ mediaData.length }})</strong>
                      <v-progress-circular
                          v-if= "loading"
                          indeterminate
                          color="accent"
                          size="20"
                      >
                      </v-progress-circular>
                    </v-btn>
                    <v-btn
                      style="margin-left: 20px"
                      color="#c22c3f"
                      dark
                      class="modal-button"
                      :disabled="archiveData.length === 0"
                      @click="archiveModal = true"
                    >
                      Show Media Archive
                        <strong v-if="loading===false">({{ archiveData.length }})</strong>
                      <v-progress-circular
                          v-if="loading"
                          indeterminate
                          color="accent"
                          size="20"
                      >
                      </v-progress-circular>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Media
                    </v-card-title>
                    <v-card-text>
                      <a
                        class="close-media"
                        href="#"
                        @click.prevent="mediaModal = false"
                      >&#x2716;</a>
                      <div v-if="totalPages">
                        <div class="display-items">
                          <div class="display-items-header">
                            <div>
                              <span>SHA1</span>
                            </div>
                            <div>
                              <span>classification</span>
                            </div>
                            <div>
                              <span>series</span>
                            </div>
                            <div>
                              <span />
                            </div>
                          </div>
                          <div
                            v-for="(media_item, index) in displayedItem"
                            :key="index"
                            class="display-item"
                          >
                            <div>
                              <a :href="mediaSearchLink(media_item.sha1)">
                                {{ media_item.sha1 }}
                              </a>
                              <img
                                  v-if="media_item.in_archive"
                                  class="archive-linked-icon"
                                  src="/static/images/icons/archive_linked.svg"
                                  :title="getArchiveLinkTitle(media_item.parent_type)">
                            </div>
                            <div v-if="media_item.classification">
                              {{ media_item.classification }}
                            </div>
                            <div v-if="media_item.series">
                              <dd v-if="media_item.series.length > 0">
                                {{ media_item.series.join(", ") }}
                              </dd>
                              <dd v-else>
                                No series
                              </dd>
                            </div>
                            <div>
                              <a :href="'/en/message-search/?query='+ media_item.doc_id">
                                <button class="btn btn--sm open-details-btn"> Show Post</button>
                              </a>
                            </div>
                          </div>
                        </div>
                        <message-search-page-nav
                          :initCurrentPage="currentPage"
                          :totalPages="totalPages"
                          @page-nav="handlePageNav"
                          @select-page="updateCurrentPage"
                        />
                      </div>
                      <div v-else>
                        <p>No Data Found</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
              <v-dialog
                v-model="postWithMediaModal"
                style="max-width: 900px;"
              >
                <v-card class="user-media-archive">
                  <v-card-title class="text-h5 grey lighten-2">
                    Posts with media in {{ siteName }}
                  </v-card-title>
                  <a
                    class="close-media"
                    href="#"
                    @click.prevent="postWithMediaModal = false"
                  >&#x2716;</a>
                  <post-with-media
                    :mediaSearchUrl="mediaSearchUrl"
                    :totalMediaPost="totalMediaPost"
                  />
                </v-card>
              </v-dialog>
              <v-dialog v-model="archiveModal">
                <v-card class="user-media-archive">
                  <v-card-title class="text-h5 grey lighten-2">
                    Archives
                  </v-card-title>
                  <a
                    class="close-media"
                    href="#"
                    @click.prevent="archiveModal = false"
                  >&#x2716;</a>
                  <v-card-text>
                    <div v-if="archiveTotalPages">
                      <div class="display-items">
                        <div class="display-items-header">
                          <div>
                            <span>Archive SHA1</span>
                          </div>
                          <div>
                            <span>filename</span>
                          </div>
                          <div>
                            <span>Archive result</span>
                          </div>
                          <div>
                            <span />
                          </div>
                          <div>
                            <span />
                          </div>
                        </div>
                        <div
                          v-for="(media_item, index) in archiveDisplayedItem"
                          :key="index"
                          class="display-item"
                        >
                          <div>
                            <a :href="mediaSearchLink(media_item.archive_sha1)">{{ media_item.archive_sha1 }}<br></a>
                          </div>
                          <div>
                            {{ media_item.filename }}
                          </div>
                          <div>
                            {{ media_item.archive_result }}
                          </div>
                          <div>
                            <user-detail-posts
                              :mediaItem="media_item"
                              :disable="disable"
                              @popup="popup"
                            />
                          </div>
                          <div>
                            <user-detail-media
                              :mediaItem="media_item"
                              :mediaSearchUrl="mediaSearchUrl"
                              :disable="disable"
                              @popup="popup"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <message-search-page-nav
                      :initCurrentPage="currentArchivePage"
                      :totalPages="archiveTotalPages"
                      @page-nav="handleArchivePageNav"
                      @select-page="updateArchiveCurrentPage"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item>
            </div>
            <div v-else class="no-posts">
              <strong v-if="totalPostLoading===false">No posts found for this user on {{siteName}}</strong>
              <v-progress-circular
                v-if="totalPostLoading===true"
                indeterminate
                color="accent"
                size="20"
            >
            </v-progress-circular>
            </div>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        sm="4"
      >
        <v-card
            v-if="totalMessages > 0"
          class="mx-auto u-p-md"
          tile
        >
          <div>
            <p><strong>"{{ userName }}"</strong> found in following sites:</p>
            <ul class="found-sites-list">
              <li
                v-for="(site, i) in sites"
                :key="i"
              >
                <img
                  v-if="site.is_tor"
                  class="watchlist-tor"
                  width="25"
                  src="/images/logos/onion.png"
                  title="This is a site found on the Tor network"
                  alt=".onion site"
                >
                <a
                  class="u-pl-sm"
                  :href="`/en/user/detail/${userName}/${site.site_name}`"
                >
                  {{ site.site_name }}
                </a>
                ({{site.doc_count}} Posts)
              </li>
            </ul>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import axios from 'axios'
import Cookies from 'js-cookie'
import MessageSearchPageNav from '../message_search/MessageSearchPageNav.vue'
import UserDetailPosts from './UserDetailPosts.vue'
import UserDetailMedia from './UserDetailMedia.vue'
import PostWithMedia from './PostWithMedia.vue'

export default {
  name: 'UserDetail',
  components: {PostWithMedia, MessageSearchPageNav, UserDetailPosts, UserDetailMedia},
  props: {
    userName: {
      type: String,
      required: true
    },
    siteNames: {
      type: Array,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      loading: false,
      totalPostLoading: false,
      allMessages: {},
      selectedItem: 1,
      totalMessages: 0,
      resultsState: 'loading',
      message_search_url: '',
      lastPostedDate: '',
      mediaModal: false,
      postWithMediaModal: false,
      mediaData: [],
      totalMediaPost: 0,
      postWithMedia: [],
      archiveData: [],
      allMedia: 0,
      allArchive: 0,
      archiveModal: false,
      isPostsHidden: true,
      isMediaHidden: true,
      perPageMax: 100,
      perPageMin: 1,
      currentItem: '',
      currentPage: 1,
      itemsPerPage: 10,
      currentArchiveItem: '',
      currentArchivePage: 1,
      sites: this.siteNames,
      disable: false
    }
  },
  computed: {
    filters() {
      return this.$store.state.filters
    },
    // for media
    displayedItem() {
      const startIdx = (this.currentPage - 1) * this.itemsPerPage
      const endIdx = startIdx + this.itemsPerPage
      return this.mediaData.slice(startIdx, endIdx)
    },
    totalPages() {
      return Math.ceil(this.mediaData.length / this.itemsPerPage)
    },
    // for archive
    archiveDisplayedItem() {
      const startIdx = (this.currentArchivePage - 1) * this.itemsPerPage
      const endIdx = startIdx + this.itemsPerPage
      return this.archiveData.slice(startIdx, endIdx)
    },
    archiveTotalPages() {
      return Math.ceil(this.archiveData.length / this.itemsPerPage)
    },
  },
  mounted() {
    this.get_messages()
    this.get_media_details()
  },
  methods: {
    handlePageNav(data) {
      if (data === 'prev') this.prevPage()
      if (data === 'next') this.nextPage()
    },
    updateCurrentPage(page) {
      this.$store.commit('start', (this.limit * parseInt(page)) - 1)
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    updateArchiveCurrentPage(page) {
      this.$store.commit('start', (this.limit * parseInt(page)) - 1)
    },
    handleArchivePageNav(data) {
      if (data === 'prev') this.prevArchivePage()
      if (data === 'next') this.nextArchivePage()
    },
    prevArchivePage() {
      if (this.currentArchivePage > 1) {
        this.currentArchivePage--
      }
    },
    nextArchivePage() {
      if (this.currentArchivePage < this.archiveTotalPages) {
        this.currentArchivePage++
      }
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      return new Date(date * 1000).toLocaleString('en-US', options)
    },
    mediaSearchLink(imgHash) {
      return `${this.mediaSearchUrl}?sha1=${imgHash}`
    },
    getArchiveLinkTitle(parent_type) {
      if (parent_type === 'archive') {
        return 'Media is also found separately on message'
      } else {
        return 'Media is also found in archive on message'
      }
    },
    get_messages() {
      this.totalPostLoading=true
      const csrftoken = Cookies.get('csrftoken')
      this.message_search_url = '/en/message-search/get_posts?posted_date=desc&query=&start=0&filters=username:' + this.userName + ',site_name:' + this.siteName
      axios.get(this.message_search_url, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.allMessages = response
        this.totalMessages = this.allMessages.data.total
        this.resultsState = 'retrieved'
        this.totalPostLoading=false
        if (this.totalMessages > 0){
          this.lastPostedDate = this.formatDate(this.allMessages.data.messages[0]._source.posted_date)
        }
      })
    },
    get_media_details() {
      this.loading=true
      const csrftoken = Cookies.get('csrftoken')
      const mediaUrl = '/en/user/media/?filters=username:' + this.userName + ',site_name:' + this.siteName
      axios.get(mediaUrl, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.mediaData = response.data.all_medias
        this.archiveData = response.data.all_archives
        this.totalMediaPost = response.data.post_with_media
        this.loading=false
      })
    },
    popup(state) {
      this.disable = state
    }
  }
}
</script>
<style scoped>
.user-media-archive {
  height: 90vh;
}
.display-items > div {
  display: flex;
  height: 55px;
  line-height: 55px;
}

.display-items-header {
  font-weight: bold;
  border-bottom: 2px solid rgb(230, 229, 229);
}

.display-item {
  border-bottom: 2px solid rgb(230, 229, 229);
}

.display-item button {
  transform: scale(0.8);
}

.display-items-header > div,
.display-item > div {
  width: 300px;
  flex-grow: 1;
}

.display-items-header > div:first-child,
.display-item > div:first-child {
  width: 500px;
}
.display-items-header > div:last-child,
.display-items-header > div:nth-last-child(2),
.display-item > div:last-child,
.display-item > div:nth-last-child(2) {
  text-align: center;
  width: 400px;
  max-width: 400px;
}
.close-media {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  line-height: normal;
}
.site_title {
  margin-left: 8px;
}
.user-header {
  padding: 10px 0;
  border-bottom: 1px solid #aaa;
  margin: 0 16px;
}
.user-header span {
  font-size: 16px;
}
.user-header h2 {
  margin-bottom: 12px;
}
.user-site {
  padding: 16px 16px 10px;
}
.found-sites-list {
  list-style: none;
  margin-left: 0;
}
.found-sites-list li {
  margin-bottom: 12px;
}
.found-sites-list li img {
  vertical-align: middle;
}
.show-posts {
  border-radius: 6px;
  padding: 2px 5px;
  background: #d5d2d1;
  font-size: 14px;
  margin-left: 10px;
  color: #474443;
}
.modal-button.theme--dark.v-btn.v-btn--disabled:disabled {
  background: #c22c3f !important;
  color: #fff !important;
}
.modal-button{
  text-transform: capitalize;
}
.no-posts{
  text-align: center;
  margin: 15px;
}
</style>
