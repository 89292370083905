<template>
  <div class="posts-container">
    <button
      v-if="mediaItem.doc_ids.length > 0"
      class="btn btn--sm open-details-btn show-posts-btn"
      :disabled="disable"
      @click="toggleVisibilityAndCallAPI"
    >
      Show Posts
    </button>
    <div
      v-if="!isHidden"
      class="posts-items"
    >
      <a
        class="close-posts"
        href="#"
        @click.prevent="hidePopup"
      >&#x2716;</a>
      <div
        v-for="(post, doc_index) in posts"
        :key="doc_index"
      >
        <a
          :href="'/en/message-search/?query='+ post.doc_id"
          style="text-decoration: none"
        >
          {{ post.post|shortenString(maxLength=53)}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'

export default {
  name: 'UserDetailPosts',
  props: {
    mediaItem: {
      type: Object,
      required: true
    },
    disable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isHidden: true,
      posts: []
    }
  },
  methods: {
    toggleVisibilityAndCallAPI() {
      this.isHidden = !this.isHidden
      if (!this.isHidden) this.getPostDetails()
      this.$emit('popup', true)
    },
    getPostDetails() {
      const csrftoken = Cookies.get('csrftoken')
      const url = '/en/view/posts/'
      const postData = {
        'doc_ids': this.mediaItem.doc_ids
      }
      axios.post(url, postData, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.posts = response.data.posts
        console.log(response)
      })
    },
    hidePopup() {
      this.isHidden = !this.isHidden
      this.$emit('popup', false)
    }
  }
}
</script>

<style scoped>
.posts-container {
  position: relative;
}
.posts-items {
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 25px;
  z-index: 10;
  padding: 20px;
  overflow: scroll;
  line-height: normal;
  text-align: left;
  width: 500px;
  max-height: 500px;
}
.close-posts {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  line-height: normal;
}
.posts-items > div {
  border-bottom: 2px solid rgb(230, 229, 229);
  margin-bottom: 8px;
  padding-top: 1.25rem;
}
.show-posts-btn:disabled {
  background: #c22c3f;
  color: #fff;
}
</style>
