<template>
  <div class="watchlist-sightings-items">
    <p id="add-error" class="watchlist-page-label-error"></p>
    <div class="result-box image-result">
      <note-buttons
        :noteId="result.sha1"
        :noteName="null"
        :noteLinked="null"
        :noteItem="'media'"
        :has-notes="result.has_notes"
        :site-link="getMediaLink()"
      ></note-buttons>
      <dl class="dl-table">
        <div class="dl-table__row">
          <dt>SHA1:</dt>
          <dd class="sha1" :id="result.sha1">
            {{ result.sha1 }}
          </dd>
        </div>
        <add-media-label
            :hash="result.sha1"
            :is_sha1="true"
            :in_watchlist="result.in_watchlist"
            :sha1_label="result.label"
            @add-data-watchlist="add_data_watchlist"
        /><br/>
        <div class="dl-table__row">
          <dt>Similarity:</dt>
          <dd>{{ result.similarity }}%</dd>
        </div>
        <div class="dl-table__row">
          <dt>Content:</dt>
          <dd>{{ result.content }}</dd>
        </div>
        <div class="dl-table__row">
          <dt>Confidence:</dt>
          <dd>{{ result.confidence }}</dd>
        </div>
      </dl>
      <media-search-sightings-table
        :mediaSha1="result.sha1"
        :sightings="result.sightings"
        :moreSightings="result.more_sightings"
        :forumSightings="result.forum_sightings"
        :chatSightings="result.chat_sightings"
        :forumUrl="forumUrl"
      />
      <br>
      <div>
        <button class="btn btn--sm" :class="{'open-list-btn': !hideArchives}" v-if="result.archives && result.archives.length > 0" @click="hideArchives = !hideArchives">
          {{ archivesBtnText }}
        </button>
        <button class="btn btn--sm" :class="{'open-list-btn': !hideVideoSightings}" v-if="result.parent_media_sha1" @click="retrieveVideoSightings(result.parent_media_sha1)">
          {{ videoSightingsBtnText }}
        </button>
      </div>
      <div class="mt-8" v-if="!hideArchives && result.archives">
        <media-search-archives
           v-if="!hideArchives && result.archives"
           :archives="result.archives"
           :media="result.sha1"
        />
      </div>
      <hr v-if="!hideVideoSightings && !hideArchives" class="mt-8 mb-8">
      <div v-if="!hideVideoSightings && videoSightings === null" class="alert alert--info u-text-center mt-8 mb-8">
          {{ gettext('Loading...') }}
      </div>
      <div v-else-if="!hideVideoSightings && videoSightings" class="mt-8">
        <dl class="dl-table mb-0">
          <div class="dl-table__row">
            <dt>Video SHA1:</dt>
            <dd class="sha1" :id="result.parent_media_sha1">
              {{ result.parent_media_sha1 }}
            </dd>
          </div>
        </dl>
        <add-media-label
            :hash="result.parent_media_sha1"
            :is_sha1="true"
            :in_watchlist="videoSightings.in_watchlist"
            :sha1_label="videoSightings.label"
            @add-data-watchlist="add_data_watchlist"
        /><br/>
        <p class="mb-2 u-fontsize-sm">
          This media was found in a video. Here are sightings for that video.
        </p>
        <media-search-sightings-table
          v-if="!hideVideoSightings && videoSightings"
          :mediaSha1="result.parent_media_sha1"
          :sightings="videoSightings.sightings"
          :moreSightings="videoSightings.more_sightings"
          :forumSightings="videoSightings.forum_sightings"
          :chatSightings="videoSightings.chat_sightings"
          :forumUrl="forumUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserNote from '../notes/UserNote'
import NoteButtons from '../notes/NoteButtons'
import AddMediaLabel from '../watchlist/AddMediaLabel'
import MediaSearchArchives from '../media_search/MediaSearchArchives'
import axios from 'axios'
import Cookies from 'js-cookie'
import MediaSearchSightingsTable from '../media_search/MediaSearchSightingsTable'

export default {
  components: {
    MediaSearchSightingsTable,
    UserNote,
    NoteButtons,
    AddMediaLabel,
    MediaSearchArchives
  },
  props: {
    result: {
      type: Object,
      default: () => {}
    },
    forumUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      currentNoteId: '',
      usernotes: [],
      showTopicLink: false,
      showInputFields: false,
      noteId: '',
      noteName: '',
      noteLinked: '',
      noteItem: '',
      hideArchives: true,
      hideVideoSightings: true,
      videoSightings: null,
    }
  },
  methods: {
    getMediaLink() {
      return new URL('/en/media/?sha1=' + this.result.sha1, document.baseURI).href
    },
    add_data_watchlist(args) {
      // add a media entry to the watchlist
      var labelInput = args['label']
      var dataVal = args['data_val']
      var mediaData = {}

      if (dataVal != null) {
        if (dataVal.trim().length === 288) {
          mediaData = {
            label: labelInput,
            pdna_key: dataVal
          }
        } else if (dataVal.trim().length === 32) {
          mediaData = {
            label: labelInput,
            sha1_key: dataVal
          }
        }

        if (Object.keys(mediaData).length > 0) {
          const csrftoken = Cookies.get('csrftoken')
          axios.post('/en/watchlist/add/', mediaData, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {console.log("got a response! %o", resp)}
          ).catch(
            err => {
              document.getElementById('add-error').innerHTML = err
              console.log("something went wrong! %o", err)
            }
          )
        }
        dataVal = ''
      }
    },
    addNoRefUserNote(value) {
      this.noteName = value['noteName']
      this.noteId = value['noteId']
      this.noteItem = value['noteItem']
      this.showInputFields = true
      this.showTopicLink = false
    },
    retrieveVideoSightings(sha1) {
      this.hideVideoSightings = !this.hideVideoSightings;
      if (!this.videoSightings) {
        const csrftoken = Cookies.get('csrftoken')
        axios.get('/en/media/get_media_sightings/', {params: {'sha1': sha1}}, {'headers': {'X-CSRFToken': csrftoken}})
            .then((response) => {
              if (response.status === 200) {
                this.videoSightings = response.data
              }
            }).catch((error) => {
              // there isn't currently any place to display errors so just log it
              console.log(error)
        });
      }
    }
  },
  computed: {
    archivesBtnText: function() {
      return (this.hideArchives) ? "Show Archives": "Hide Archives";
    },
    videoSightingsBtnText: function() {
      return (this.hideVideoSightings) ? "Show Video Sightings" : "Hide Video Sightings";
    },
  }
}
</script>

<style scoped>
.result-box {
  position: relative;
}
.result-box .note-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-application ul {
 padding-left: 0
}
.v-application code::after, .v-application code::before {
  content: none;
}
.image-result {
  overflow-x: hidden;
}
.open-list-btn {
  color: hsl(11,3%,40%);;
  background-color: #fff;
  border: solid 1px hsl(11,3%,40%);;
}
.open-list-btn:hover {
  background-color: hsl(11,3%,96%);
}
</style>
